@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the color palettes from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette:  map.get($color-config, 'accent');
    $warn-palette:    map.get($color-config, 'warn');
    $is-dark-theme:   map.get($color-config, 'is-dark');

    .login-error-message-box mat-card {
        background-color: mat.get-color-from-palette($warn-palette, 50);
        color: mat.get-color-from-palette($warn-palette, "50-contrast");
    }
}

@mixin typography($theme) {
    // Get the typography config from the theme.
    $typography-config: mat.get-typography-config($theme);

}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }

    $typography-config: mat.get-typography-config($theme);
    @if $typography-config != null {
        @include typography($theme);
    }
}


.login-full-width {
    width: 100%
}

// .login-card-title-row {
//     width: 100%;
//     display: flex;
//     flex-direction: row;
// }

.login-action-button {
    margin-left: 12px;
    margin-right: 12px;
}

.login-spacer {
    flex: 1;
    width: auto;
}
