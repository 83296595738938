@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the color palettes from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette:  map.get($color-config, 'accent');
    $warn-palette:    map.get($color-config, 'warn');
    $is-dark-theme:   map.get($color-config, 'is-dark');

    div.landing-member-option-highlight mat-card {
        background-color: mat.get-color-from-palette($primary-palette, 50);
        color: mat.get-color-from-palette($primary-palette, "50-contrast");
    }

}

@mixin typography($theme) {
    // Get the typography config from the theme.
    $typography-config: mat.get-typography-config($theme);

}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }

    $typography-config: mat.get-typography-config($theme);
    @if $typography-config != null {
        @include typography($theme);
    }
}


.landing-main {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    min-height: 50vh;
    // width: 100%;
}

.landing-main-spacer {
    flex: 1;
}

.landing-main-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

div.landing-member-option {
    margin: 20px;
}

div.landing-member-option mat-card {
    width: 300px;
    height: 100%;
}

.landing-full-width {
    width: 100%
}

@media only screen and (max-width: 959.98px) {
    .landing-main-options {
        display: flex;
        flex-direction: column;
    }
    div.landing-member-option mat-card {
        width: 100%;
        height: auto;
    }
}