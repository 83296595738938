@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the color palettes from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette:  map.get($color-config, 'accent');
    $warn-palette:    map.get($color-config, 'warn');
    $is-dark-theme:   map.get($color-config, 'is-dark');

    .profile-error-message-box mat-card {
        background-color: mat.get-color-from-palette($warn-palette, 50);
        color: mat.get-color-from-palette($warn-palette, "50-contrast");
    }
}
    
@mixin typography($theme) {
    // Get the typography config from the theme.
    $typography-config: mat.get-typography-config($theme);

}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }

    $typography-config: mat.get-typography-config($theme);
    @if $typography-config != null {
        @include typography($theme);
    }
}


.profile-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

// .profile-member-details {
//     display: flex;
//     flex-direction: column;
//     width: 800px;
// }

.profile-card {
    margin: 24px;
}


@media only screen and (max-width: 959.98px) {
    .profile-main {
        flex-direction: column;
    }

    // .profile-member-details {
    //     width: auto;
    // }
    .profile-card {
        margin: 12px;
    }
}