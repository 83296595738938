@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the color palettes from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette:  map.get($color-config, 'accent');
    $warn-palette:    map.get($color-config, 'warn');
    $is-dark-theme:   map.get($color-config, 'is-dark');

    // mat-toolbar {
    //     background-color: mat.get-color-from-palette($primary-palette, 200);
    // }
}

@mixin typography($theme) {
    // Get the typography config from the theme.
    $typography-config: mat.get-typography-config($theme);

    // app-chart-web-visitors .axis-x text {
    //     font-family: mat.font-family($typography-config);
    // }

    // app-chart-web-visitors .axis-y text {
    //     font-family: mat.font-family($typography-config);
    // }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }

    $typography-config: mat.get-typography-config($theme);
    @if $typography-config != null {
        @include typography($theme);
    }
}

.toolbar-title {
    margin-left: 24px;
}

// .toolbar-logo-gt-sm {
//     height: 50px;
// }

// .toolbar-logo-sm {
//     height: 50px;
// }

// .toolbar-logo-lt-sm {
//     height: 30px;
// }

// .toolbar-logo-area {
//     width: 322px;
// }

.toolbar-button {
    margin-left: 24px;
}

.toolbar-logo {
    height: 50px;
}

.toolbar-spacer {
    flex: 1;
}

@media only screen and (max-width: 959.98px) {
    .toolbar-logo {
        height: 30px;
    }
    .toolbar-title {
        margin-left: 12px;
    }
    .toolbar-button {
        margin-left: 12px;
    }
}