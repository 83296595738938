// Custom Theming for Angular Material
@use '@angular/material' as mat;
@use './app/footer/footer.component.scss' as footer;
@use './app/toolbar/toolbar.component.scss' as toolbar;
@use './app/landing-page/landing-page.component.scss' as landing-page;
@use './app/login/login.component.scss' as login;
@use './app/profile/profile.component.scss' as profile;
// @use './app/chart-web-visitors/chart-web-visitors.component.scss' as chart-web-visitors;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

//               "@angular/material/prebuilt-themes/purple-green.css",

$primary: mat.define-palette(mat.$purple-palette, 700, 500, 800);
$accent: mat.define-palette(mat.$green-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($theme);
@include mat.all-component-themes($theme);
// @include footer.theme($theme);
// @include toolbar.theme($theme);
@include landing-page.theme($theme);
@include login.theme($theme);
// @include profile.theme($theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.full-width {
  width: 100%;
}